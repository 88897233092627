import React from "react";
import { Box, Circle, Flex ,Image} from "@chakra-ui/react";
export default function ButtonD() {
  return (
    <Flex
      h={["50px", "60px"]}
      w={["180px", "230px"]}
      mt="30px"
      bg="
    #ffa800"
      borderWidth="0px"
      borderRadius="lg"
      style={{
        borderTopRightRadius: "5px",
        borderBottomRightRadius: "5px",
        borderTopLeftRadius: "30px",
        borderBottomLeftRadius: "30px",
      }}
    >
      <Circle
        fontSize={["25px"]}
        bg="#D98F00"
        w={["50px", "60px"]}
        h={["50px", "60px"]}
        textAlign="center"
        rounded="100%"
        fontWeight={["600"]}
        color="black"
        pb={["0","4px"]}
      >
        <Image src="ticket.png"  w={["25px", "30px"]}
        h={["25px", "30px"]} >

        </Image>
      </Circle>
      <Flex
        bg="
      #ffa800"
        fontSize="15px"
        color="black"
        alignItems="center"
        rounded="5px"
      >
        <a href="https://in.bookmyshow.com/activities/ms-vs-mrs/ET00393427">
        <Box
          ml={["18px", "32px"]}
          fontFamily="oswald"
          fontSize={["18px", "20px"]}
        >
          BOOK NOW
        </Box>
        </a>
      </Flex>
    </Flex>
  );
}