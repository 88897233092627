import { Box, Flex, Image, Text, useMediaQuery } from "@chakra-ui/react";
import React from "react";

export default function AboutSec() {
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  return (
    <Flex
      w="100vw"
      direction="column"
      color="black"
      bg="white"
      h={["100%","1300px"]}
      pb={["30px","0"]}
      justifyContent={["center", ""]}
    >
      <Box ml={["20px", "240px"]} mr={["0", "240px"]} mt={["3rem"]}>
        <Box>
          <Flex>
            <Box>
              <Flex direction="column">
                <Box alignItems="flex-start" w={["90vw", "655px"]}>
                  <Text
                    fontFamily="Oswald"
                    fontSize={["26px", "32px"]}
                    fontWeight="500"
                  >
                    About Sachi Events
                  </Text>
                  <Text fontFamily="Oswald" fontSize={["16px", "26px"]}>
                    Turn your dream events into a reality with Sachi. Whether
                    you envision an indoor or outdoor celebration, we bring your
                    vision to life, allowing you to relax and savour the
                    occasion.
                  </Text>
                </Box>

                <Box alignItems="flex-start" w={["85vw", "655px"]} mt="1.4rem">
                  {!isLargerThan800 ? (
                    <Box h="100%">
                      <Image src="target.png" />
                    </Box>
                  ) : (
                    ""
                  )}
                  <Text
                    fontFamily="Oswald"
                    fontSize={["26px", "32px"]}
                    fontWeight="500"
                  >
                    Our Vission :
                  </Text>
                  <Text fontFamily="Oswald" fontSize={["16px", "26px"]}>
                    To redefine the industry by architecting captivating events
                    that inspire.
                  </Text>
                </Box>
              </Flex>
            </Box>
            {isLargerThan800 ? (
              <Box h="462px" w="665px">
                <Image src="target.png" />
              </Box>
            ) : (
              ""
            )}
          </Flex>

          <Flex direction={["column", "row"]}>
            {isLargerThan800 ? (
              <Box>
                <Image src="rocket.png" />
              </Box>
            ) : (
              <Image src="rocket.png" h="50%" w="80%"/>
            )}
            <Box>
              <Flex>
                <Box
                  alignItems="flex-start"
                  w={["85vw", "655px"]}
                  mt={["0", "10rem"]}
                >
                  <Text
                    fontFamily="Oswald"
                    fontSize={["26px", "32px"]}
                    fontWeight="500"
                  >
                    Our Mission:
                  </Text>
                  <Text fontFamily="Oswald" fontSize={["16px", "26px"]}>
                    To foster positive change in society through exceptional
                    events.
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Flex>

          <Flex direction={["column", "row"]}>
            {!isLargerThan800 ? (
              <Image
                src="arrows.png"
                h="
                  30%" w="80%" pr="50px"
              />
            ) : (
              ""
            )}
            <Box>
              <Flex direction={["column", "row"]}>
                <Box
                  alignItems="flex-start"
                  w={["85vw", "655px"]}
                  mt={["0", "10rem"]}
                >
                  <Text
                    fontFamily="Oswald"
                    fontSize={["26px", "32px"]}
                    fontWeight="500"
                  >
                    Our Way:
                  </Text>
                  <Text fontFamily="Oswald" fontSize={["16px", "26px"]}>
                    Experiences Designed for Today's Clientele.
                  </Text>
                </Box>
              </Flex>
            </Box>
            {isLargerThan800 ? (
              <Box h="460px" w="665px">
                <Image src="arrows.png" />
              </Box>
            ) : (
              ""
            )}
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
}