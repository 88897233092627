import { Box, Flex, Text, VStack, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import Spon from "./Spon";
import Spons from "./Spons"


export default function AboutUs() {
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");

  return (
    <>
      <Flex
   
        backgroundImage="bag_s.png"
        backgroundSize="cover"
        backgroundPosition="center"
        alignItems="center"
        direction="column"
       
        justifyContent="center"
        h={!isLargerThan800 ? "412px" : "519px"} // Adjusted height for responsiveness
        w={["60vh", "100vw"]} s
      >
       <Box mx="auto" textAlign="center"> 
  <Flex alignItems="center" justifyContent="center" flexDirection="column"> 
    <VStack spacing="0">
      <Text color="white" fontSize={["32px", "55.94px"]} fontFamily="Oswald">
        What’s in the
      </Text>
      <Text color="white" fontSize={["32px", "55.94px"]} fontFamily="Oswald">
        Bag For Sponsors?
      </Text>
    </VStack>
  </Flex>
</Box>

      </Flex>

      <Box>
        <Spon />
        <Spons/>
      </Box>
    </>
  );
}
