import React from "react";
import { Box, Flex, Image, Text, VStack, Heading } from "@chakra-ui/react";

export default function CompSec() {
  return (
    <>
      <Box w="100%" h="auto" bg="white">
        <Flex ml={["10px","240px"]} mr={["10px","240px"]} mt="1.6rem" direction="column">
          <Box w= {["300px","700px"]} fontSize= {["14px","26px"]} fontFamily="Oswald">
            <Text fontSize={["20px","32px"]}>Exciting Competitions on Day 1 and Day 2</Text>
            <Text>The finals are scheduled for Sunday, April 28th. The prize distribution ceremony will be held in</Text>
            <Text>the evening following the Event’s conclusion.</Text>
          </Box>

          <Box fontFamily="Oswald" mt="2rem">
            <Flex alignItems="center" justifyContent="center" direction="column" >
            <Heading
                  fontSize={{ base: "1.6rem", md: "2.6rem" }}
                
                  fontWeight="600"
                >
                  Age Group
                </Heading>
                <Text m="8px" ml="0px" fontSize= {["14px","26px"]} >
                  This event welcomes women of all ages, divided into three
                  groups:
                </Text>

                <Flex
                  direction={{ base: "column", md: "row" }}
                  justifyContent="space-between"
                  
                
                  gap="5rem"
                
                  m="auto"
                >
                  {/* Card 1 */}
                  <Box
                    p="1rem"
                    w= {["140px","180px"]}
                    h= {["230px","270px"]}
                    bg="white"
                    borderRadius="12px"
                    textAlign="center"
                    boxShadow="md"
                   
                  >
                    <VStack align="center" mb={{ base: "1rem", md: "0" }}>
                      <Image
                        src="p2.png"
                        alt="Age Group 1"
                        p="1rem"
                        objectFit="contain"
                        borderRadius="full"
                       
                        boxShadow="md"
                      />
                      <Text mt="4px" fontSize="1rem" fontWeight="600">
                        {" "}
                        Teen Dreamers{" "}
                      </Text>
                      <Text>(Under 20)</Text>
                    </VStack>
                  </Box>

                  {/* Card 2 */}
                  <Box
                    p="1rem"
                    w= {["140px","180px"]}
                    h= {["230px","270px"]}
                    bg="white"
                    borderRadius="12px"
                    textAlign="center"
                    boxShadow="md"
                   
                  >
                    <VStack align="center" mb={{ base: "1rem", md: "0" }}>
                      <Image
                        src="p3.png"
                        alt="Age Group 1"
                        p="1rem"
                        objectFit="contain"
                        borderRadius="full"
                        w={["100px","200px"]}
                        h={["120px","160px"]}
                        boxShadow="md"
                      />
                      <Text mt="4px" fontSize="1rem" fontWeight="600">
                        {" "}
                        Teen Dreamers{" "}
                      </Text>
                      <Text>(Under 20)</Text>
                    </VStack>
                  </Box>

                  {/* Card 3 */}
                  <Box
                    p="1rem"
                    w= {["140px","180px"]}
                    h= {["230px","270px"]}
                    bg="white"
                    borderRadius="12px"
                    textAlign="center"
                    boxShadow="md"
                   
                  >
                    <VStack align="center" mb={{ base: "1rem", md: "0" }}>
                      <Image
                        src="p1.png"
                        alt="Age Group 1"
                        p="1rem"
                        objectFit="contain"
                        borderRadius="full"
                        w={["100px","200px"]}
                        h={["120px","160px"]}
                        boxShadow="md"
                      />
                      <Text mt="4px" fontSize="1rem" fontWeight="600">
                        {" "}
                        Teen Dreamers{" "}
                      </Text>
                      <Text>(Under 20)</Text>
                    </VStack>
                  </Box>
                </Flex>
                <Text m="1rem" ml="0px" fontSize= {["14px","26px"]} >
                  No matter your age, there’s always something new to discover.
                </Text>

            </Flex>
          </Box>

          <Box mt="1rem" pb="2rem" fontFamily="Oswald" fontSize={["20px","32px"]}> 
            <Text>Competitions and Timings</Text>
            <Image
            
            src="table.png" w="1000px" mt="1rem"></Image>
          </Box>
        </Flex>
      </Box>
    </>
  );
}
