import React from "react";
import { Box, Flex, Image, Text,  Heading } from "@chakra-ui/react";

export default function Eventhosts() {
  const hosts = ["kiki.png", "para.png", "shil.png"];
 


  return (
    <>
      <Box w="100%" h="auto" bg="white" p={["1rem","2rem"]}>
        <Flex
          ml={["10px", "240px"]}
          mr={["10px", "240px"]}
          mt="1.6rem"
          direction="column"
         
        >
          <Box
            w={["300px", "200px"]}
            fontSize={["14px", "26px"]}
            fontFamily="Oswald"
          >
            <Text fontSize={["20px", "32px"]}>
              Exciting Competitions on Day 1 and Day 2
            </Text>
          </Box>
          <Box w={["300px", "auto"]} fontFamily="Oswald" >
            <Text fontSize={["14px", "26px"]}>
              The finals are scheduled for Sunday, April 28th.{" "}
            </Text>
            <Text fontSize={["14px", "26px"]}>
              The prize distribution ceremony will be held in the evening
              following the Event’s conclusion.{" "}
            </Text>
          </Box>

          <Box mt="2rem">
            <Flex
              alignItems="center"
              justifyContent="center"
              direction="column"
            >
              <Heading
                fontSize={{ base: "1.6rem", md: "2.6rem" }}
                fontWeight="800"
              >
                Host
              </Heading>

              <Flex
                direction={{ base: "column", md: "row" }}
                justifyContent="space-between"
                gap="5rem"
                m="auto"
                mt="2rem"
                p="auto"
              >
                {hosts.map((image, index) => (
                  <Image
                    key={index}
                    src={image}
                    w={["181px", "181px"]}
                    h={["auto", "auto"]}
                  />
                ))}
              </Flex>
            </Flex>
          </Box>
{/*
          <Box mt="2rem">
            <Flex
              alignItems="center"
              justifyContent="center"
              direction="column"
            >
              <Heading
                fontSize={{ base: "1.6rem", md: "2.6rem" }}
                fontWeight="800"
              >
                Singers
              </Heading>

              <Flex
                direction={{ base: "column", md: "row" }}
                justifyContent="space-between"
                gap="5rem"
                m="auto"
                flexWrap="wrap"
                mt="2rem"
              >
                {singers.slice(0, 4).map((image, index) => (
                  <Image
                    key={index}
                    src={image}
                    w={["181px", "181px"]}
                    h={["auto", "auto"]}
                  />
                ))}
              </Flex>
              <Flex
                direction={{ base: "column", md: "row" }}
                justifyContent="space-between"
                gap="5rem"
                m="auto"
             
              >
                {singers.slice(3, 6).map((image, index) => (
                  <Image
                    key={index}
                    src={image}
                    w={["181px", "181px"]}
                    h={["auto", "auto"]}
                  />
                ))}
              </Flex>
            </Flex>
          </Box>
          <Box mt="2rem">
            <Flex
              alignItems="center"
              justifyContent="center"
              direction="column"
            >
              <Heading
                fontSize={{ base: "1.6rem", md: "2.6rem" }}
                fontWeight="800"
              >
                Stand up comedy
              </Heading>

              <Flex
                direction={{ base: "column", md: "row" }}
                justifyContent="space-between"
                gap="5rem"
                m="auto"
                mt="2rem"
                p="auto"
              >
                {com.map((image, index) => (
                  <Image
                    key={index}
                    src={image}
                    w={["181px", "181px"]}
                    h={["auto", "auto"]}
                  />
                ))}
              </Flex>
            </Flex>
          </Box>
                */}

        </Flex>
      </Box>
    </>
  );
}
